import _ from 'lodash';

import {
    CREATE_QUOTATION_SUCCESS,
    GET_QUOTATIONS_REQUEST,
    GET_QUOTATIONS_SUCCESS,
    GET_QUOTATION_REQUEST,
    GET_QUOTATION_SUCCESS,
    PATCH_QUOTATION_SUCCESS,
    ADD_OPERATION,
    UPDATE_OPERATION,
    RESET_OPERATION,
    DELETE_OPERATION,
    ADD_PRODUCT,
    UPDATE_MAIN_PRODUCT,
    UPDATE_OTHER_PRODUCT,
    DELETE_OTHER_PRODUCT,
    DELETE_ALL_OTHER_PRODUCTS,
    UPDATE_OPERATION_INSTALLATION_DATE,
    UPDATE_OPERATION_TECHNICIAN,
    UPDATE_QUOTATION,
    SAVE_QUOTATION_SUCCESS,
    SAVE_QUOTATION_AS_DRAFT_SUCCESS,
    GET_QUOTATIONS_ANALYTICS_SUCCESS,
    SEND_QUOTATION_BY_EMAIL_SUCCESS,
    SEND_QUOTATION_BY_EMAIL_FAILURE,
    RESET_QUOTATION,
    ADD_MAIN_PRODUCT_VARIATION,
    UPDATE_MAIN_PRODUCT_VARIATION,
    DELETE_MAIN_PRODUCT_VARIATION,
    DELETE_QUOTATION_SUCCESS,
    UPDATE_QUOTATION_PRODUCT_SUCCESS,
    CLEAR_QUOTATION,
    REFUSE_QUOTATION_SUCCESS,
    UPDATE_WASTE_MANAGEMENT,
    RESET_QUOTATION_CONTRACT_SUCCESS,
    SET_QUOTATION_SENT_TO_CUSTOMER_SUCCESS,
} from '../types/quotation.types';
import { productTypes, quotationStatuses } from '../../utils/enums';
import productFormHelper from '../../utils/product-form-helper';
import { isNonEmptyObject } from '../../utils';

function initState() {
    return {
        quotations: [],
        quotation: {},
        analytics: {
            quotations: { totalAmountOfQuotationsSent: 0, totalAmountOfQuotationsApproved: 0 },
        },
    };
}

export default function (state = initState(), action) {
    switch (action.type) {
        case CREATE_QUOTATION_SUCCESS:
            return {
                ...state,
                quotation: action.payload.quotation,
            };

        case GET_QUOTATIONS_REQUEST:
            return {
                ...state,
            };

        case GET_QUOTATIONS_SUCCESS:
            return {
                ...state,
                quotations: action.payload.quotations,
            };

        case GET_QUOTATION_REQUEST:
            return {
                ...state,
                quotation: {},
            };

        case GET_QUOTATION_SUCCESS:
            return {
                ...state,
                quotation: action.payload.quotation,
            };

        case PATCH_QUOTATION_SUCCESS:
            return {
                ...state,
                quotation: {
                    ...action.payload.quotation,
                },
            };

        case ADD_OPERATION:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: [...state.quotation.operations, action.payload.operation],
                    ...resetPricingProperties(),
                },
            };

        case UPDATE_WASTE_MANAGEMENT:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    wasteManagement: {
                        ...action.payload.wasteManagement,
                    },
                },
            };

        case UPDATE_OPERATION: {
            const { additionalRelatedOperations = [] } = action.payload;

            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            return {
                                ...operation,
                                ...action.payload.updates,
                                products: updateOperationMainProduct(operation, action.payload.mainProductUpdates),
                            };
                        } else {
                            const matchingAdditionRelatedOperation = extractMatchingAdditionalRelatedOperation(additionalRelatedOperations, operation);
                            if (isNonEmptyObject(matchingAdditionRelatedOperation)) {
                                return {
                                    ...operation,
                                    ...matchingAdditionRelatedOperation.updates,
                                };
                            }
                        }

                        return operation;
                    }),
                },
            };
        }

        case RESET_OPERATION:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) return action.payload.operation;

                        return operation;
                    }),
                    ...resetPricingProperties(),
                },
            };

        case DELETE_OPERATION:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.filter(({ uuid }) => uuid !== action.payload.operationUUID),
                    ...resetPricingProperties(),
                },
            };

        case ADD_PRODUCT:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            const { products = [] } = operation;
                            products.push(action.payload.product);

                            return {
                                ...operation,
                                products,
                            };
                        }

                        return operation;
                    }),
                },
            };

        case UPDATE_MAIN_PRODUCT:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            return {
                                ...operation,
                                ...action.payload.operationUpdates,
                                products: updateOperationMainProduct(operation, action.payload.updates),
                            };
                        }

                        return operation;
                    }),
                },
            };

        case UPDATE_OTHER_PRODUCT:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            return {
                                ...operation,
                                products: operation.products.map((product) => {
                                    if (product.uuid === action.payload.productUUID) {
                                        const updatedProduct = {
                                            ...product,
                                            ...action.payload.updates,
                                        };
                                        if (['quantity', 'price'].every((property) => Object.keys(updatedProduct).includes(property))) {
                                            updatedProduct.totalPrice = updatedProduct.quantity * updatedProduct.price;
                                        }

                                        return updatedProduct;
                                    }

                                    return product;
                                }),
                            };
                        }

                        return operation;
                    }),
                },
            };

        case DELETE_OTHER_PRODUCT: {
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            return {
                                ...operation,
                                products: operation.products.filter(({ uuid }) => uuid !== action.payload.productUUID),
                            };
                        }

                        return operation;
                    }),
                },
            };
        }

        case DELETE_ALL_OTHER_PRODUCTS: {
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            return {
                                ...operation,
                                products: operation.products.filter(({ product }) => product.type !== productTypes.OTHER_PRODUCT),
                            };
                        }

                        return operation;
                    }),
                },
            };
        }

        case UPDATE_OPERATION_INSTALLATION_DATE:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            return {
                                ...operation,
                                installationDate: action.payload.installationDate,
                            };
                        }

                        return operation;
                    }),
                },
            };

        case UPDATE_OPERATION_TECHNICIAN:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            return {
                                ...operation,
                                technician: action.payload.technician,
                            };
                        }

                        return operation;
                    }),
                },
            };

        case UPDATE_QUOTATION:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    ...action.payload.updates,
                },
            };

        case SAVE_QUOTATION_SUCCESS:
        case SAVE_QUOTATION_AS_DRAFT_SUCCESS:
            return {
                ...state,
                quotation: applyQuotationChangesAfterSave(state, action),
            };

        case GET_QUOTATIONS_ANALYTICS_SUCCESS: {
            return {
                ...state,
                analytics: {
                    quotations: action.payload.analytics,
                },
            };
        }

        case SEND_QUOTATION_BY_EMAIL_SUCCESS:
            return {
                ...state,
                quotation: action.payload.quotation,
            };

        case SEND_QUOTATION_BY_EMAIL_FAILURE:
            return {
                ...state,
                error: action.error,
            };

        case ADD_MAIN_PRODUCT_VARIATION:
        case UPDATE_MAIN_PRODUCT_VARIATION:
            return {
                ...state,
                quotation: applyMainProductVariationsChanges(state, action),
            };

        case DELETE_MAIN_PRODUCT_VARIATION:
            return {
                ...state,
                quotation: {
                    ...applyMainProductVariationsChanges(state, action),
                    ...resetPricingProperties(),
                },
            };

        case RESET_QUOTATION:
            return {
                ...state,
                quotation: {},
            };

        case REFUSE_QUOTATION_SUCCESS: {
            return {
                ...state,
                quotations: state.quotations.filter(({ uuid }) => uuid !== action.payload.quotation.uuid),
            };
        }
        case DELETE_QUOTATION_SUCCESS:
            return {
                ...state,
                quotations: state.quotations.filter(({ uuid }) => uuid !== action.payload.quotation.uuid),
            };

        case UPDATE_QUOTATION_PRODUCT_SUCCESS:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    operations: state.quotation.operations.map((operation) => {
                        return {
                            ...operation,
                            products: operation.products.map((product) => {
                                if (product.product.uuid === action.payload.product.uuid) {
                                    return {
                                        ...product,
                                        product: action.payload.product,
                                    };
                                }

                                return product;
                            }),
                        };
                    }),
                },
            };

        case RESET_QUOTATION_CONTRACT_SUCCESS:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    contract: action.payload.quotation.contract,
                },
            };

        case CLEAR_QUOTATION: {
            return {
                ...state,
                quotation: {},
            };
        }

        case SET_QUOTATION_SENT_TO_CUSTOMER_SUCCESS: {
            const index = state.quotations.findIndex((quotation) => quotation.uuid === action.payload.quotation.uuid);
            const newArray = [...state.quotations];

            newArray[index].status = quotationStatuses.SENT;

            return {
                ...state,
                quotations: newArray,
            };
        }

        default:
            return state;
    }
}

function updateOperationMainProduct(operation, updates) {
    return operation.products.map((product) => {
        if (product.product.type === productTypes.MAIN_PRODUCT) {
            const updatedMainProduct = {
                ...product,
                ...updates,
            };
            if (['quantity', 'price'].every((property) => Object.keys(updatedMainProduct).includes(property))) {
                updatedMainProduct.totalPrice = updatedMainProduct.quantity * updatedMainProduct.price;
            }

            return updatedMainProduct;
        }

        return product;
    });
}

function resetPricingProperties() {
    return {
        commercialDiscount: 0,
        energySavingCertificatePremium: 0,
        customerEnergySavingCertificatePremium: 0,
        totalPriceToPay: 0,
        totalNetPriceToPay: 0,
        pricesPerVatRate: [],
    };
}

function applyMainProductVariationsChanges(state, action) {
    const { type, payload } = action;
    const { operationUUID, variationUUID, variation, updates } = payload;

    return {
        ...state.quotation,
        operations: state.quotation.operations.map((operation) => {
            let variationsTotalLength = 0;
            if (operation.uuid === operationUUID) {
                const products = operation.products.map((product) => {
                    if (product.product.type === productTypes.MAIN_PRODUCT) {
                        const strategies = {
                            [ADD_MAIN_PRODUCT_VARIATION]: addMainProductVariation,
                            [UPDATE_MAIN_PRODUCT_VARIATION]: updateMainProductVariation,
                            [DELETE_MAIN_PRODUCT_VARIATION]: deleteMainProductVariation,
                        };
                        const selectedStrategy = strategies[type];
                        const variations = selectedStrategy ? selectedStrategy({ variations: product.declinations, variationUUID, variation, updates }) : [];
                        variationsTotalLength = variations.reduce((accumulator, { length = 0 }) => accumulator + length, 0);
                        const quantity = variationsTotalLength;
                        const totalPrice = quantity * product.price;
                        const blockValidity = productFormHelper.validateMainProduct({ ...product, quantity, totalPrice, declinations: variations });

                        return { ...product, declinations: variations, quantity, totalPrice, blockValidity };
                    }

                    return product;
                });

                return { ...operation, products, unitValue: variationsTotalLength };
            }

            return operation;
        }),
    };
}

function addMainProductVariation({ variations = [], variation } = {}) {
    return [...variations, variation];
}

function updateMainProductVariation({ variations = [], variationUUID, updates } = {}) {
    return variations.map((variation) => {
        if (variation.uuid === variationUUID) return { ...variation, ...updates };
        return variation;
    });
}

function deleteMainProductVariation({ variations = [], variationUUID }) {
    return variations.filter((variation) => variation.uuid !== variationUUID);
}

function applyQuotationChangesAfterSave(state, action) {
    const { quotation } = action.payload;

    return {
        ...quotation,
        operations: quotation.operations.map((operation) => ({
            ...operation,
            products: operation.products.map((product) => {
                if (!productFormHelper.isCalorifugeProduct(_.get(product, 'product.category'))) return product;

                const declinations = mergeLocalWithRemoteProductDeclinations(state, product);
                const blockValidity = productFormHelper.validateMainProduct({ ...product, declinations });

                return {
                    ...product,
                    blockValidity,
                    declinations,
                };
            }),
        })),
    };
}

function mergeLocalWithRemoteProductDeclinations({ quotation }, product) {
    const { declinations } = product;
    const products = extractProducts(quotation.operations);
    const matchingProduct = products.find(({ uuid }) => product.uuid === uuid);
    if (matchingProduct) declinations.push(...matchingProduct.declinations);

    return removeRedundantDeclinations(declinations);
}

function extractProducts(operations = []) {
    return operations.reduce((accumulator, { products }) => {
        accumulator.push(...products);
        return accumulator;
    }, []);
}

function removeRedundantDeclinations(declinations = []) {
    return declinations.filter((declination, index) => declinations.findIndex(({ uuid }) => declination.uuid === uuid) === index);
}

function extractMatchingAdditionalRelatedOperation(additionalRelatedOperations, operation) {
    return additionalRelatedOperations.find(({ operationUUID }) => operationUUID === operation.uuid);
}
