export const LABEL_TAB_ALL_INVOICES = 'Toutes les factures';

export const LABEL_EXPORT_INVOICES_PREFIX = 'EXPORT_RENOLIB_FACTURES_';

export const LABEL_INPUT_INVOICE_ISSUE_DATE = 'Date de la facture';
export const LABEL_INSTALLATION_START_DATE = "Date d'installation";
export const LABEL_INSTALLATION_END_DATE = "Date de fin d'installation";

export const LABEL_BUTTON_FINALIZE_INVOICE = 'FINALISER LA FACTURE';

export const LABEL_BACK_TO_INVOICE_EDITION_PAGE = 'Retourner à la facture en cours de saisie';

export const LABEL_CREATE_INVOICE = 'CRÉER UNE FACTURE';

export const LABEL_SELECT_QUOTATION_FOR_INVOICE = 'Sélectionner le devis à facturer';
