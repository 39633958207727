import {
    CREATE_CUSTOMER_PM_REQUEST,
    CREATE_CUSTOMER_PM_SUCCESS,
    CREATE_CUSTOMER_PM_FAILURE,
    GET_CUSTOMER_PM_REQUEST,
    GET_CUSTOMER_PM_SUCCESS,
    GET_CUSTOMER_PM_FAILURE,
    GET_CUSTOMERS_PM_REQUEST,
    GET_CUSTOMERS_PM_SUCCESS,
    GET_CUSTOMERS_PM_FAILURE,
    PATCH_CUSTOMER_PM_REQUEST,
    PATCH_CUSTOMER_PM_SUCCESS,
    PATCH_CUSTOMER_PM_FAILURE,
    DELETE_CUSTOMER_PM_REQUEST,
    DELETE_CUSTOMER_PM_SUCCESS,
    DELETE_CUSTOMER_PM_FAILURE,
} from '../types/customer-pm.types';
import customerPMService from '../services/customer-pm.service';
import { parseError } from '../../api';
import formActions from './ui/form';
import dialogsActions from './ui/dialogs';
import history from '../../utils/history';
import { customerPageTypes } from '../../utils/enums';

function createCustomerPM({ customerPMData } = {}) {
    return (dispatch) => {
        dispatch({ type: CREATE_CUSTOMER_PM_REQUEST });
        dispatch(formActions.startRequesting());

        customerPMService
            .createCustomerPM(customerPMData)
            .then(({ data }) => {
                dispatch({ type: CREATE_CUSTOMER_PM_SUCCESS, payload: { customerPM: data } });
                dispatch(formActions.stopRequesting());
                history.push(`/clients/${data.uuid}?type=${customerPageTypes.CUSTOMER_PM}`);
                dispatch(dialogsActions.showSuccessDialog({ message: 'Votre client a bien été enregistré' }));
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch({ type: CREATE_CUSTOMER_PM_FAILURE, payload: { errorMessage } });
                dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            });
    };
}

function getCustomerPM({ customerPMUUID } = {}) {
    return (dispatch) => {
        dispatch({ type: GET_CUSTOMER_PM_REQUEST });

        customerPMService
            .getCustomerPM(customerPMUUID)
            .then(({ data }) => {
                dispatch({ type: GET_CUSTOMER_PM_SUCCESS, payload: { customerPM: data } });
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch({ type: GET_CUSTOMER_PM_FAILURE, payload: { errorMessage } });
            });
    };
}

function getCustomersPM() {
    return (dispatch) => {
        dispatch({ type: GET_CUSTOMERS_PM_REQUEST, payload: [] });

        customerPMService
            .getCustomersPM()
            .then(({ data }) => {
                dispatch({ type: GET_CUSTOMERS_PM_SUCCESS, payload: { customersPM: data } });
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch({ type: GET_CUSTOMERS_PM_FAILURE, payload: { errorMessage } });
            });
    };
}

function patchCustomerPM({ operation, path, value, successMessage } = {}) {
    return (dispatch, getState) => {
        dispatch({ type: PATCH_CUSTOMER_PM_REQUEST });
        dispatch(formActions.startRequesting());
        const customerPMUUID = getState().customersPMState.customerPM.uuid;
        const accountUUID = getState().auth.account.uuid;

        customerPMService
            .patchCustomerPM(customerPMUUID, path, operation, value, accountUUID)
            .then(({ data }) => {
                dispatch({ type: PATCH_CUSTOMER_PM_SUCCESS, payload: { customerPM: data } });
                dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showSuccessDialog({ message: successMessage }));
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch(formActions.stopRequesting());
                dispatch({ type: PATCH_CUSTOMER_PM_FAILURE, payload: { errorMessage } });
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            });
    };
}

function deleteCustomerPM({ customerPMUUID } = {}) {
    return (dispatch, getState) => {
        dispatch({ type: DELETE_CUSTOMER_PM_REQUEST });
        dispatch(formActions.startRequesting());
        const accountUUID = getState().auth.account.uuid;

        customerPMService
            .deleteCustomerPM(customerPMUUID, accountUUID)
            .then(({ data }) => {
                dispatch({ type: DELETE_CUSTOMER_PM_SUCCESS, payload: { customerPM: data } });
                dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showSuccessDialog({ message: 'Le compte client a bien été supprimé' }));
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch(formActions.stopRequesting());
                dispatch({ type: DELETE_CUSTOMER_PM_FAILURE, payload: { errorMessage } });
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            });
    };
}

export default {
    createCustomerPM,
    getCustomerPM,
    getCustomersPM,
    patchCustomerPM,
    deleteCustomerPM,
};
