export const QUOTATION_NOT_SENDABLE_NOR_PRINTABLE = "Merci de compléter et enregistrer le devis avant d'effectuer cette action";
export const QUOTATION_NOT_SENDABLE_AND_SIGNED = 'Ce devis a déjà été signé par le client et ne peut donc plus être envoyé';
export const QUOTATION_NOT_SENDABLE_AND_INVOICED = 'Ce devis a déjà été facturé et ne peut donc plus être envoyé';
export const QUOTATION_NOT_SENDABLE_BECAUSE_OF_EXPIRATION = 'La date de fin de validité de ce devis est dépassée. Le devis ne peut plus être envoyé';
export const QUOTATION_NOT_SENDABLE_BECAUSE_OF_WASTE_DEPOT = 'Ce devis contient une gestion de dechets non valide';
export const QUOTATION_NOT_INVOICEABLE = 'Merci de signer le devis avant de pouvoir créer la facture';
export const QUOTATION_NOT_INVOICEABLE_AND_INVOICED = 'Ce devis a déjà été facturé';
export const QUOTATION_NOT_DELETABLE = 'Ce devis a déjà été signé ou envoyé et ne peut donc plus être supprimé';
export const QUOTATION_NOT_DELETABLE_API_MESSAGE = 'Ce devis a déjà été signé et par conséquent ne peut plus être modifié ou supprimé';
export const QUOTATION_NOT_DELETABLE_AND_INVOICED = 'Ce devis a déjà été facturé et ne peut donc plus être supprimé';
export const QUOTATION_NOT_ADDABLE_OPERATIONS = "Merci de complémenter l'opération ci-dessus avant d'ajouter une nouvelle";

export const NO_QUOTATIONS = "Aucun devis n'a été créé.";
export const NO_SENT_QUOTATIONS = 'Aucun devis envoyé.';
export const NO_SIGNED_QUOTATIONS = 'Aucun devis à facturer.';
export const NO_QUOTATION_VAT_CERTIFICATES = 'Aucune attestation TVA trouvée.';
export const ADMINISTRATIVE_PROBLEM_MESSAGE = 'Problème administratif';

export const TABLE_COLUMN_CADRES_CONTRIBUTION = 'Cadre(s) de contribution';
export const LABEL_CONSULT_CADRE_CONTRIBUTION = 'Consulter le cadre';
export const LABEL_NO_CADRE_CONTRIBUTION = 'Aucun cadre';

export const BACK_TO_QUOTATION = 'Retourner au devis en cours de saisie';

export const WASTE_MANAGEMENT_DEPOT_CREATION = 'Votre depot a été crée avec succes';
