import validator from './validator';
import { isValidValue } from '..';

function validateProperty(propertyDescriptor, value, formErrors, additionalData) {
    let validProperty = true;
    const { dataType, required, pattern, requiredIf, min, minItems, errorMessages = {}, property, label } = propertyDescriptor;
    const dataTypeValidationCallbacks = {
        emailAddress: validator.validateValueAsEmailAddress,
        phoneNumber: validator.validateValueAsPhoneNumber,
        iban: validator.validateValueAsIban,
    };

    if (required) validProperty = validator.validateValueAsRequired(value, property, label, formErrors, errorMessages);
    if (requiredIf) validProperty = validator.validateValueAsRequiredIf(value, requiredIf, additionalData, property, label, formErrors);
    if (!validProperty) return validProperty;
    if (!isValidValue(value)) return true;

    if (dataType) validProperty = dataTypeValidationCallbacks[dataType](value, property, label, formErrors);
    if (pattern) validProperty = validator.validateValueAsPattern(value, pattern, property, label, formErrors);
    if (min) validProperty = validator.validateValueAsMin(value, min, property, label, formErrors);
    if (minItems) validProperty = validator.validateValueMinItems(value, minItems, property, label, formErrors, errorMessages);

    return validProperty;
}

function validateForm(formState = {}, entityDescriptor = [], additionalData = {}, additionalValidationCallback) {
    let validForm = true;
    const formErrors = {};

    entityDescriptor.forEach((propertyDescriptor) => {
        const validProperty = validateProperty(propertyDescriptor, formState[propertyDescriptor.property], formErrors, { ...formState, ...additionalData });
        validForm = validForm && validProperty;
    });
    if (additionalValidationCallback) {
        const valid = additionalValidationCallback(formState, formErrors);
        validForm = validForm && valid;
    }
    if (doesFormStateIncludeAtLeastOneValidAddressPart(formState)) delete formErrors.address;

    return { validForm, formErrors };
}

function doesFormStateIncludeAtLeastOneValidAddressPart(formState) {
    return ['streetNumber', 'streetName', 'city', 'postalCode', 'country'].some((propertyName) => isValidValue(formState[propertyName]));
}

async function validateFormAsync(formState = {}, entityDescriptor = [], additionalData = {}) {
    let { validForm, formErrors } = validateForm(formState, entityDescriptor, additionalData);
    const validationTasks = entityDescriptor
        .filter((propertyDescriptor) => canProcessProvidedValidationOnProperty(propertyDescriptor, formErrors))
        .map(async function ({ validate }) {
            const validProperty = await validate(formState, formErrors);
            validForm = validForm && validProperty;
        });
    await Promise.all(validationTasks);

    return { validForm, formErrors };
}

function canProcessProvidedValidationOnProperty({ property, validate }, formErrors) {
    return isValidValue(validate) && !isValidValue(formErrors[property]);
}

export default {
    validateForm,
    validateFormAsync,
};
