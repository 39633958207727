import {
    GET_ORGANIZATION_SUCCESS,
    GET_ORGANIZATION_FAILURE,
    PATCH_ORGANIZATION_SUCCESS,
    CREATE_ORGANIZATION_SUCCESS,
    UPDATE_ORGANIZATION_ONBOARDING_SUCCESS,
    GET_ORGANIZATION_REQUEST,
    DELETE_INVITATION_SUCCESS,
    SHOW_SUBSCRIPTION_MODAL,
    HIDE_SUBSCRIPTION_MODAL,
} from '../types/organization.types';
import { APPLY_PROMOTION_CODE_SUCCESS } from '../types/payments.types';

const initState = () => ({
    organization: null,
    loading: true,
    subscriptionModalShown: false,
});

export default (state = initState(), action) => {
    switch (action.type) {
        case GET_ORGANIZATION_REQUEST:
            return {
                ...state,
                subscriptionModalShown: false,
                organization: action.payload,
                loading: true,
            };
        case GET_ORGANIZATION_SUCCESS:
            return {
                ...state,
                organization: action.payload,
                loading: false,
            };
        case GET_ORGANIZATION_FAILURE:
            return {
                ...state,
                organization: null,
                loading: false,
            };
        case CREATE_ORGANIZATION_SUCCESS: {
            return {
                ...state,
                organization: action.payload,
            };
        }
        case PATCH_ORGANIZATION_SUCCESS:
            return {
                ...state,
                organization: action.payload,
            };
        case UPDATE_ORGANIZATION_ONBOARDING_SUCCESS:
            return {
                ...state,
                organization: action.payload,
            };

        case DELETE_INVITATION_SUCCESS:
            return {
                ...state,
                organization: {
                    ...state.organization,
                    organizationInvitations: state.organization.organizationInvitations.filter((invitation) => invitation.uuid !== action.payload.invitation.uuid),
                },
            };

        case SHOW_SUBSCRIPTION_MODAL:
            return {
                ...state,
                organization: action.payload.organization,
                subscriptionModalShown: true,
            };

        case HIDE_SUBSCRIPTION_MODAL:
            return {
                ...state,
                subscriptionModalShown: false,
            };

        case APPLY_PROMOTION_CODE_SUCCESS:
            return {
                ...state,
                organization: {
                    ...state.organization,
                    appliedPromotionCodes: action.payload.organization.appliedPromotionCodes,
                },
            };

        default:
            return state;
    }
};
