export const DIFFERENT_PASSWORDS = 'Vos mots de passe ne sont pas identiques';

export const UNAUTHORIZED_EMAIL = 'Adresse email non autorisée';

export const INVALID_ADDRESS = "Nous n'avons pas pu récupérer toutes les informations concernant l'adresse. Veuillez renseigner les informations manquantes.";

export const MUST_PROVIDE_AT_LEAT_ONE_PHONE_NUMBER = 'Vous devez renseigner au moins un numéro de téléphone';

export const PHONE_LENGTH_MUST_BE_VALID = 'Votre numéro de téléphone doit être composé de 10 chiffres';

export const TABLE_COLUMN_START_DATE = 'Date de début';
export const TABLE_COLUMN_END_DATE = 'Date de fin';
