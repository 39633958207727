import { SHOW_CONFIRM_DAILOG, SHOW_SUCCESS_DIALOG, SHOW_FAILURE_DIALOG, SHOW_SUBSCRIPTION_FAILURE_DIALOG, HIDE_DIALOGS } from '../../types/ui/dialogs';

const showConfirmDialog = ({ message, onConfirm, withChoice, confirmedButtonText, closable }) => ({
    type: SHOW_CONFIRM_DAILOG,
    payload: { message, onConfirm, confirmedButtonText, withChoice, closable },
});

const showSuccessDialog = ({ message }) => ({
    type: SHOW_SUCCESS_DIALOG,
    payload: { message },
});

const showFailureDialog = ({ message, messages }) => ({
    type: SHOW_FAILURE_DIALOG,
    payload: { message, messages },
});

const hideDialogs = () => ({
    type: HIDE_DIALOGS,
});

function showSubscriptionFailureDialog({ message }) {
    return { type: SHOW_SUBSCRIPTION_FAILURE_DIALOG, payload: { message } };
}

export default {
    showConfirmDialog,
    showSuccessDialog,
    showFailureDialog,
    hideDialogs,
    showSubscriptionFailureDialog,
};
