import React from 'react';
import loadable from '@loadable/component';
import { pagesType, sidebarMenuRoutes, sidebarMenuTranslations } from './utils/enums';

const Dashboard = React.lazy(() => import('./components/pages/Dashboard'));
const Organization = React.lazy(() => import('./components/pages/Organization'));
const SubContractors = React.lazy(() => import('./components/pages/SubContractors'));
const Contracts = React.lazy(() => import('./components/pages/Contracts'));
const Customers = React.lazy(() => import('./components/pages/Customers'));
const Quotations = React.lazy(() => import('./components/pages/Quotations'));
const Invoices = React.lazy(() => import('./components/pages/Invoices'));
const Invoice = React.lazy(() => import('./components/pages/quotation/Quotation'));
const InvoiceEdition = React.lazy(() => import('./components/pages/invoice/InvoiceEdition'));
const InvoicePreview = React.lazy(() => import('./components/pages/invoice/InvoicePreview'));
const DossiersCEE = React.lazy(() => import('./components/pages/DossiersCEE'));
const Documents = React.lazy(() => import('./components/pages/Documents'));
const Planning = React.lazy(() => import('./components/pages/Planning'));
const SubContractor = React.lazy(() => import('./components/pages/SubContractor'));
const Profile = React.lazy(() => import('./components/pages/Profile'));
const Customer = React.lazy(() => import('./components/pages/Customer'));
const Quotation = React.lazy(() => import('./components/pages/quotation/Quotation'));
const QuotationEdition = React.lazy(() => import('./components/pages/quotation/QuotationEdition'));
const QuotationPreview = React.lazy(() => import('./components/pages/quotation/QuotationPreview'));
const DossierCEE = React.lazy(() => import('./components/pages/DossierCEE'));
const Document = React.lazy(() => import('./components/pages/Document'));
const ProductCatalogue = React.lazy(() => import('./components/pages/ProductCatalogue'));
const Product = React.lazy(() => import('./components/pages/Product'));
const Contract = React.lazy(() => import('./components/pages/Contract'));
const NoAccess = React.lazy(() => import('./components/pages/NoAccess'));

const privateRoutes = [
    {
        label: 'Tableau de bord',
        path: '/tableau-de-bord',
        name: pagesType.DASHBOARD,
        icon: 'dashboard',
        component: Dashboard,
        isMenuItem: true,
    },
    {
        label: 'Organisation',
        path: '/organisation',
        name: pagesType.ORGANIZATION,
        icon: 'organization',
        component: Organization,
        isMenuItem: true,
    },
    {
        label: 'Sous-traitants',
        name: pagesType.SUB_CONTRACTORS,
        path: '/sous-traitants',
        icon: 'subcontractors',
        component: SubContractors,
        exact: true,
        isMenuItem: true,
    },
    {
        label: 'Fiche contrat',
        path: '/contrats/:uuid',
        component: Contract,
        name: pagesType.CONTRACTS,
    },
    {
        label: sidebarMenuTranslations.CONTRACTS,
        path: sidebarMenuRoutes.CONTRACTS,
        name: pagesType.CONTRACTS,
        icon: 'contracts',
        component: Contracts,
        isMenuItem: true,
    },

    {
        label: 'Clients',
        path: '/clients',
        icon: 'customers',
        name: pagesType.CUSTOMERS,
        component: Customers,
        exact: true,
        isMenuItem: true,
    },
    {
        label: 'Catalogue produits',
        path: '/catalogue-produits',
        icon: 'products',
        name: pagesType.PRODUCTS,
        component: ProductCatalogue,
        isMenuItem: true,
        exact: true,
    },
    {
        label: 'Catalogue produits',
        path: '/catalogue-produits/:uuid',
        icon: '',
        component: Product,
        name: pagesType.PRODUCTS,
        exact: true,
    },
    {
        label: 'Devis',
        path: '/devis',
        icon: 'quotations',
        name: pagesType.QUOTATIONS,
        component: Quotations,
        isMenuItem: true,
        exact: true,
    },
    {
        label: 'Factures',
        path: '/factures',
        name: pagesType.INVOICES,
        icon: 'invoices',
        component: Invoices,
        isMenuItem: true,
        exact: true,
    },
    {
        label: 'Factures',
        path: '/factures/:uuid',
        name: pagesType.INVOICES,
        component: Invoice,
        exact: true,
    },
    {
        label: 'Edition facture',
        path: '/factures/:uuid/edition',
        name: pagesType.INVOICES,
        component: InvoiceEdition,
    },
    {
        label: 'Apercu facture',
        path: '/factures/:uuid/apercu',
        name: pagesType.INVOICES,
        component: InvoicePreview,
    },
    {
        label: 'Dossiers de financement',
        name: pagesType.DOSSIERS_CEE,
        path: '/dossiers-financement',
        icon: 'certificates',
        component: DossiersCEE,
        isMenuItem: true,
        exact: true,
    },
    {
        path: '/pas-d-acces',
        component: NoAccess,
        exact: true,
    },
    {
        label: 'Ajout de documents',
        path: '/documents',
        name: pagesType.DOCUMENTS,
        icon: 'documents',
        component: Documents,
        isMenuItem: true,
        exact: true,
    },
    {
        label: 'Planning',
        path: '/planning',
        name: pagesType.PLANNING,
        icon: 'planning',
        component: Planning,
        isMenuItem: true,
        exact: true,
    },
    {
        label: 'Fiche sous traitant',
        path: '/sous-traitants/:uuid',
        name: pagesType.SUB_CONTRACTORS,
        component: SubContractor,
    },
    {
        label: 'Profile',
        path: '/profil',
        component: Profile,
    },
    {
        label: 'Fiche client',
        path: '/clients/:uuid',
        name: pagesType.CUSTOMERS,
        component: Customer,
    },
    {
        label: 'Devis',
        path: '/devis/:uuid',
        name: pagesType.QUOTATIONS,
        component: Quotation,
        exact: true,
    },
    {
        label: 'Edition devis',
        path: '/devis/:uuid/edition',
        name: pagesType.QUOTATIONS,
        component: QuotationEdition,
    },
    {
        label: 'Apercu devis',
        path: '/devis/:uuid/apercu',
        name: pagesType.QUOTATIONS,
        component: QuotationPreview,
    },
    {
        label: 'Dossier financement',
        path: '/dossiers-financement/:uuid',
        name: pagesType.DOSSIERS_CEE,
        component: DossierCEE,
    },
    {
        label: 'Document',
        path: '/documents/:uuid',
        name: pagesType.DOCUMENTS,
        component: Document,
    },
];

const sidebarMenuItems = privateRoutes.filter((privateRoute) => privateRoute.isMenuItem).map(({ component, ...sidebarMenuItem }) => sidebarMenuItem);

const Login = loadable(() => import('./components/pages/Login'));
const Register = loadable(() => import('./components/pages/Register'));
const EmailValidation = loadable(() => import('./components/pages/EmailValidation'));
const RecoverPassword = loadable(() => import('./components/pages/RecoverPassword'));
const SetNewPassword = loadable(() => import('./components/pages/SetNewPassword'));
const AppointmentStatusRedirect = loadable(() => import('./components/pages/AppointmentStatusRedirect'));

const publicRoutes = [
    {
        path: '/connexion',
        component: Login,
    },
    {
        path: '/inscription-f9lo56ch21uwtz9',
        component: Register,
        exact: true,
    },
    {
        path: '/inscription-f9lo56ch21uwtz9/:token',
        component: Register,
    },
    {
        path: '/validation/:token',
        component: EmailValidation,
    },
    {
        path: '/recuperer-mot-de-passe',
        component: RecoverPassword,
    },
    {
        path: '/changement-de-mot-de-passe/:token',
        component: SetNewPassword,
    },
    {
        path: '/:role/appointment/:status/token/:token',
        component: AppointmentStatusRedirect,
    },
];

const privateRoutesThatDontNeedAccountCompletion = ['/tableau-de-bord', '/profil'];

export { sidebarMenuItems, privateRoutes, publicRoutes, privateRoutesThatDontNeedAccountCompletion };
