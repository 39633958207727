export const INVOICE_NOT_SENDABLE_AND_PAYED = 'Cette facture a déjà été payée et ne peut plus être envoyée.';
export const INVOICE_NOT_SENDABLE_AND_CANCELLED_OR_RECTIFIED = 'Cette facture a été annulée ou rectifiée et ne peut plus être envoyée.';
export const INVOICE_NOT_SENDABLE_AND_DRAFT = "Merci de finaliser la facture avant de l'envoyer.";

export const INVOICE_NOT_PAYABLE_AND_DRAFT = "Merci de finaliser et d'envoyer la facture avant de confirmer le paiement.";
export const INVOICE_NOT_PAYABLE_AND_FINALIZED = "Merci d'envoyer la facture avant de confirmer le paiement.";
export const INVOICE_NOT_PAYABLE_AND_CANCELLED_OR_RECTIFIED = 'Cette facture a été annulée ou rectifiée et ne peut plus être envoyée.';
export const INVOICE_ALREADY_PAID = 'Cette facture a déjà été déclarée comme payée.';
export const INVOICE_NOT_PAYABLE_AND_FINALIZED_AND_TYPE_IS_NOT_CLASSIC = "Une facture d'avoir ne peut pas être payée.";

export const INVOICE_NOT_CANCELLABLE_AND_FINALIZED = 'Vous ne pouvez pas supprimer une facture finalisée.';
export const INVOICE_ALREADY_CANCELLED = 'Cette facture a déjà été annulée ou rectifiée.';

export const INVOICE_MUST_BE_SAVED = 'Veuiller enregistrer la facture.';

export const NO_SIGNED_QUOTATIONS_FOR_INVOICE = "Vous n'avez pas de devis signé en attente de facturation.";
export const NO_INVOICES = "Aucune facture n'a été créée.";

export const NO_INVOICES_TO_SEND = 'Aucune facture à envoyer.';

export const NO_EXPORTABLE_INVOICES = 'Vous devez finaliser au moins une facture avant de pouvoir utiliser cette fonctionnalité.';

export const CONFIRM_CANCEL_INVOICE_DIALOG = 'Une nouvelle facture va être générée suite à l’annulation de votre facture n°';
