import {
    LABEL_EPAISSEUR,
    LABEL_RESISTANCE,
    LABEL_EFFICACITE_ENERGETIQUE,
    LABEL_TYPE_FONCTIONNEMENT,
    LABEL_MARQUE_REGULATEUR,
    LABEL_CLASSE_REGULATEUR,
    LABEL_REFERENCE_REGULATEUR,
    LABEL_FACTEUR_SOLAIRE,
    LABEL_TYPE_DE_CONSTITUTION,
    LABEL_COEFFICIANT_DE_TRANSMISSION,
    LABEL_PUISSANCE_THERMIQUE,
    LABEL_MODELE_VENTILATION,
    LABEL_TYPE_VENTILATION,
    LABEL_CLASSE_ENERGETIQUE_VENTILATION,
    LABEL_REFERENCE_AVIS_TECHNIQUE,
    LABEL_DATE_VALIDITE_AVIS_TECHNIQUE,
    LABEL_PUISSANCE_ELECTRIQUE,
    LABEL_TYPE_CAISSON,
    LABEL_MARQUE_CAISSON,
    LABEL_REFERENCE_CAISSON,
    LABEL_MARQUE_BOUCHES_EXTRACTION,
    LABEL_REFERENCE_BOUCHES_EXTRACTION,
    LABEL_MARQUE_BOUCHE_AIR,
    LABEL_REFERENCE_BOUCHE_AIR,
    LABEL_LA_POMPE_A_CHALEUR_EST_DE_TYPE_AIR_EAU,
    LABEL_COEFFICIANT_DE_PERFORMANCE,
    HYBRID_SOLAR_CAPTORS,
    LABEL_NATURE_FLUID_CAPTORS,
    LABEL_SEASONAL_PERFORMANCE_COEFFICIENT,
    LABEL_NOMINAL_POWER,
    LABEL_GREEN_FLAME_LABEL_SEVEN,
    LABEL_NOMINAL_YIELD,
    LABEL_PARTICLE_EMISSION,
    LABEL_CARBON_MONOXIDE,
    LABEL_NITROGEN_OXIDE_EMISSIONS,
} from './product-constants';
import { productTypes, productCategoriesValues, productSubCategories, productFileTypes } from './enums';
import { isNullishOrEmpty, isValidValue, removeNullishProperties } from '.';

/*
 * Insulator
 */
function setEnegeryEfficiencyPropertiesForInsulator(data, energyEfficiencyProperties) {
    energyEfficiencyProperties.push({ label: LABEL_RESISTANCE, value: data[LABEL_RESISTANCE] || 0 });
}

function setProductDetailsToSpecifyForInsulator(data, productDetailsToSpecify) {
    productDetailsToSpecify.push({ label: LABEL_EPAISSEUR, value: data[LABEL_EPAISSEUR] });
}

/*
 * Heating pump
 */
function setEnegeryEfficiencyPropertiesForHeatingPump({ subCategory, ...data }, energyEfficiencyProperties) {
    if (subCategory === productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_AIR_AIR) {
        energyEfficiencyProperties.push({ label: LABEL_SEASONAL_PERFORMANCE_COEFFICIENT, value: data[LABEL_SEASONAL_PERFORMANCE_COEFFICIENT] });
        energyEfficiencyProperties.push({ label: LABEL_NOMINAL_POWER, value: data[LABEL_NOMINAL_POWER] });
    } else energyEfficiencyProperties.push({ label: LABEL_EFFICACITE_ENERGETIQUE, value: data[LABEL_EFFICACITE_ENERGETIQUE] });
}

function setProductDetailsToSpecifyForHeatingPump({ subCategory, ...data }, productDetailsToSpecify) {
    if (subCategory === productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_AIR_AIR) return;

    pushSpecifiedDetailInProductDetailsToSpecify({ productDetailsToSpecify, data, label: LABEL_TYPE_FONCTIONNEMENT });
    if (subCategory === productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_HYBRIDE) {
        [
            { label: LABEL_MARQUE_REGULATEUR },
            { label: LABEL_REFERENCE_REGULATEUR },
            { label: LABEL_CLASSE_REGULATEUR },
            { label: LABEL_LA_POMPE_A_CHALEUR_EST_DE_TYPE_AIR_EAU, defaultValue: false },
        ].forEach(({ label, defaultValue }) => {
            pushSpecifiedDetailInProductDetailsToSpecify({ productDetailsToSpecify, data, label, defaultValue });
        });
    }
}

function pushSpecifiedDetailInProductDetailsToSpecify({ productDetailsToSpecify, data, label, defaultValue }) {
    const value = data[label] || defaultValue;
    if (isValidValue(value)) productDetailsToSpecify.push({ label, value });
}

/*
 * Roof
 */
function setEnegeryEfficiencyPropertiesForRoof(data, energyEfficiencyProperties) {
    energyEfficiencyProperties.push({ label: LABEL_FACTEUR_SOLAIRE, value: data[LABEL_FACTEUR_SOLAIRE] });
}

function setProductDetailsToSpecifyForRoof(data, productDetailsToSpecify) {
    productDetailsToSpecify.push({ label: LABEL_TYPE_DE_CONSTITUTION, value: data[LABEL_TYPE_DE_CONSTITUTION] });
}

/*
 * Window
 */
function setEnegeryEfficiencyPropertiesForWindow(data, energyEfficiencyProperties) {
    energyEfficiencyProperties.push({ label: LABEL_FACTEUR_SOLAIRE, value: data[LABEL_FACTEUR_SOLAIRE] });
    energyEfficiencyProperties.push({ label: LABEL_COEFFICIANT_DE_TRANSMISSION, value: data[LABEL_COEFFICIANT_DE_TRANSMISSION] });
}

function setProductDetailsToSpecifyForWindow() {}

/*
 * Individual boiler
 */
function setEnegeryEfficiencyPropertiesForIndividualBoiler(data, energyEfficiencyProperties) {
    energyEfficiencyProperties.push({ label: LABEL_EFFICACITE_ENERGETIQUE, value: data[LABEL_EFFICACITE_ENERGETIQUE] });
    energyEfficiencyProperties.push({ label: LABEL_PUISSANCE_THERMIQUE, value: data[LABEL_PUISSANCE_THERMIQUE] });
}

function setProductDetailsToSpecifyForIndividualBoiler(data, productDetailsToSpecify) {
    productDetailsToSpecify.push({ label: LABEL_MARQUE_REGULATEUR, value: data[LABEL_MARQUE_REGULATEUR] });
    productDetailsToSpecify.push({ label: LABEL_REFERENCE_REGULATEUR, value: data[LABEL_REFERENCE_REGULATEUR] });
    productDetailsToSpecify.push({ label: LABEL_CLASSE_REGULATEUR, value: data[LABEL_CLASSE_REGULATEUR] });
}

/*
 * Ventilation
 */
function setEnegeryEfficiencyPropertiesForVentilation(data, energyEfficiencyProperties) {
    energyEfficiencyProperties.push({ label: LABEL_PUISSANCE_ELECTRIQUE, value: data[LABEL_PUISSANCE_ELECTRIQUE] });
}

function setProductDetailsToSpecifyForVentilation(data, productDetailsToSpecify) {
    productDetailsToSpecify.push({ label: LABEL_TYPE_VENTILATION, value: data[LABEL_TYPE_VENTILATION] });
    productDetailsToSpecify.push({ label: LABEL_CLASSE_ENERGETIQUE_VENTILATION, value: data[LABEL_CLASSE_ENERGETIQUE_VENTILATION] });
    productDetailsToSpecify.push({ label: LABEL_REFERENCE_AVIS_TECHNIQUE, value: data[LABEL_REFERENCE_AVIS_TECHNIQUE] });
    productDetailsToSpecify.push({ label: LABEL_DATE_VALIDITE_AVIS_TECHNIQUE, value: data[LABEL_DATE_VALIDITE_AVIS_TECHNIQUE] });
    productDetailsToSpecify.push({ label: LABEL_TYPE_CAISSON, value: data[LABEL_TYPE_CAISSON] });
    productDetailsToSpecify.push({ label: LABEL_MARQUE_CAISSON, value: data[LABEL_MARQUE_CAISSON] });
    productDetailsToSpecify.push({ label: LABEL_REFERENCE_CAISSON, value: data[LABEL_REFERENCE_CAISSON] });
    productDetailsToSpecify.push({ label: LABEL_MARQUE_BOUCHES_EXTRACTION, value: data[LABEL_MARQUE_BOUCHES_EXTRACTION] });
    productDetailsToSpecify.push({ label: LABEL_REFERENCE_BOUCHES_EXTRACTION, value: data[LABEL_REFERENCE_BOUCHES_EXTRACTION] });

    if (data[LABEL_MARQUE_BOUCHE_AIR] && data[LABEL_REFERENCE_BOUCHE_AIR]) {
        productDetailsToSpecify.push({ label: LABEL_MARQUE_BOUCHE_AIR, value: data[LABEL_MARQUE_BOUCHE_AIR] });
        productDetailsToSpecify.push({ label: LABEL_REFERENCE_BOUCHE_AIR, value: data[LABEL_REFERENCE_BOUCHE_AIR] });
    }
}

function setProductDetailsForSolarWaterHeater(data, productDetailsToSpecify) {
    productDetailsToSpecify.push({ label: LABEL_NATURE_FLUID_CAPTORS, value: data[LABEL_NATURE_FLUID_CAPTORS] });
}
function setProductExclusionDetailsForSolarWaterHeater(data, productExclusionDetailsToSpecify) {
    productExclusionDetailsToSpecify.push({ label: HYBRID_SOLAR_CAPTORS, value: data[HYBRID_SOLAR_CAPTORS] });
}
/*
 * Thermodynamic water heater
 */
function setEnegeryEfficiencyPropertiesForThermodynamicWaterHeater(data, energyEfficiencyProperties) {
    energyEfficiencyProperties.push({ label: LABEL_COEFFICIANT_DE_PERFORMANCE, value: data[LABEL_COEFFICIANT_DE_PERFORMANCE] });
}

function setEnegeryEfficiencyPropertiesForSolarWaterHeater(data, energyEfficiencyProperties) {
    return energyEfficiencyProperties;
}

/*
 * Independent wood stove
 */
function setProductDetailsForIndependentWoodStove(data, productDetailsToSpecify) {
    productDetailsToSpecify.push({ label: LABEL_GREEN_FLAME_LABEL_SEVEN, value: data[LABEL_GREEN_FLAME_LABEL_SEVEN] });
    productDetailsToSpecify.push({ label: LABEL_TYPE_FONCTIONNEMENT, value: data[LABEL_TYPE_FONCTIONNEMENT] });
}

function setEnegeryEfficiencyPropertiesForIndependentWoodStove(data, energyEfficiencyProperties) {
    if (isValidValue(data[LABEL_GREEN_FLAME_LABEL_SEVEN]) && !data[LABEL_GREEN_FLAME_LABEL_SEVEN]) {
        energyEfficiencyProperties.push({ label: LABEL_NOMINAL_YIELD, value: data[LABEL_NOMINAL_YIELD] });
        energyEfficiencyProperties.push({ label: LABEL_PARTICLE_EMISSION, value: data[LABEL_PARTICLE_EMISSION] });
        energyEfficiencyProperties.push({ label: LABEL_CARBON_MONOXIDE, value: data[LABEL_CARBON_MONOXIDE] });
        energyEfficiencyProperties.push({ label: LABEL_NITROGEN_OXIDE_EMISSIONS, value: data[LABEL_NITROGEN_OXIDE_EMISSIONS] });
    }
}

function parseCategoryData(category, operationPrices, categoryData) {
    const energyEfficiencyProperties = [];
    const detailsPerOperation = {};
    const productDetailsToSpecify = [];
    const productExclusionDetailsToSpecify = [];
    const setEnegeryEfficiencyPropertiesStrategies = {
        [productCategoriesValues.ISOLANT]: setEnegeryEfficiencyPropertiesForInsulator,
        [productCategoriesValues.POMPE_CHALEUR]: setEnegeryEfficiencyPropertiesForHeatingPump,
        [productCategoriesValues.TOITURE]: setEnegeryEfficiencyPropertiesForRoof,
        [productCategoriesValues.FENETRE]: setEnegeryEfficiencyPropertiesForWindow,
        [productCategoriesValues.CHAUDIERE_INDIVIDUELLE]: setEnegeryEfficiencyPropertiesForIndividualBoiler,
        [productCategoriesValues.VENTILATION]: setEnegeryEfficiencyPropertiesForVentilation,
        [productCategoriesValues.CHAUFFE_EAU_THERMODYNAMIQUE]: setEnegeryEfficiencyPropertiesForThermodynamicWaterHeater,
        [productCategoriesValues.CHAUFFE_EAU_SOLAIRE_INDIVIDUEL]: setEnegeryEfficiencyPropertiesForSolarWaterHeater,
        [productCategoriesValues.APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS]: setEnegeryEfficiencyPropertiesForIndependentWoodStove,
    };
    const setEnergyEfficiencyPropertiesStrategy = setEnegeryEfficiencyPropertiesStrategies[category];
    if (setEnergyEfficiencyPropertiesStrategy) setEnergyEfficiencyPropertiesStrategy(categoryData, energyEfficiencyProperties);

    const setProductDetailsToSpecifyStrategies = {
        [productCategoriesValues.ISOLANT]: setProductDetailsToSpecifyForInsulator,
        [productCategoriesValues.POMPE_CHALEUR]: setProductDetailsToSpecifyForHeatingPump,
        [productCategoriesValues.TOITURE]: setProductDetailsToSpecifyForRoof,
        [productCategoriesValues.FENETRE]: setProductDetailsToSpecifyForWindow,
        [productCategoriesValues.CHAUDIERE_INDIVIDUELLE]: setProductDetailsToSpecifyForIndividualBoiler,
        [productCategoriesValues.VENTILATION]: setProductDetailsToSpecifyForVentilation,
        [productCategoriesValues.CHAUFFE_EAU_SOLAIRE_INDIVIDUEL]: setProductDetailsForSolarWaterHeater,
        [productCategoriesValues.APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS]: setProductDetailsForIndependentWoodStove,
    };

    const setProductExclusionDetailsToSpecifyStrategies = {
        [productCategoriesValues.CHAUFFE_EAU_SOLAIRE_INDIVIDUEL]: setProductExclusionDetailsForSolarWaterHeater,
    };

    const setProductDetailsToSpecifyStrategy = setProductDetailsToSpecifyStrategies[category];
    const setProductExclusionDetailstoSpecifyStrategy = setProductExclusionDetailsToSpecifyStrategies[category];

    if (setProductDetailsToSpecifyStrategy) setProductDetailsToSpecifyStrategy(categoryData, productDetailsToSpecify);
    if (setProductExclusionDetailstoSpecifyStrategy) setProductExclusionDetailstoSpecifyStrategy(categoryData, productExclusionDetailsToSpecify);
    operationPrices.forEach(({ operationSheetName, formattedOperationSheetName, defaultPrice, vatRate }) => {
        detailsPerOperation[operationSheetName] = {};
        detailsPerOperation[operationSheetName].defaultPrice = defaultPrice;
        detailsPerOperation[operationSheetName].details = {};
        detailsPerOperation[operationSheetName].details.productDetailsToSpecify = productDetailsToSpecify;
        detailsPerOperation[operationSheetName].details.productExclusionDetailsToSpecify = productExclusionDetailsToSpecify;
        detailsPerOperation[operationSheetName].vatRate = vatRate;
        detailsPerOperation[operationSheetName].formattedOperationSheetName = formattedOperationSheetName;

        if (category === productCategoriesValues.VENTILATION) {
            detailsPerOperation[operationSheetName].vmcTypeAorB = categoryData[LABEL_TYPE_VENTILATION];
        }
    });

    return { energyEfficiencyProperties, detailsPerOperation };
}

function getName(category, categoryData) {
    if (category !== productCategoriesValues.VENTILATION) return category;

    return categoryData[LABEL_MODELE_VENTILATION];
}

function parseProductFormData({
    category,
    subCategory,
    installationType,
    title,
    file,
    brand,
    reference,
    certification,
    description,
    operationPrices,
    installationDescription,
    productDetails,
    ...categoryData
}) {
    const parsedCategoryData = parseCategoryData(category, operationPrices, { subCategory, ...categoryData });
    const parsedFormData = removeNullishProperties({
        category,
        subCategory,
        installationType,
        title,
        name: getName(category, categoryData),
        brand,
        file,
        reference,
        certification,
        description,
        installationDescription,
        productDetails,
        type: productTypes.MAIN_PRODUCT,
        fileType: getFileType(categoryData),
        options: getOptions(categoryData),
        ...parsedCategoryData,
    });
    if (isNullishOrEmpty(productDetails)) delete parsedFormData.productDetails;

    return parsedFormData;
}

function getFileType(data) {
    if (data[LABEL_GREEN_FLAME_LABEL_SEVEN]) {
        return productFileTypes.GREEN_FLAME_LABEL_SEVEN;
    }
}

function getOptions(data) {
    if (isValidValue(data[LABEL_GREEN_FLAME_LABEL_SEVEN]) && !data[LABEL_GREEN_FLAME_LABEL_SEVEN] && data?.productFiles?.length > 0) {
        return {
            fileTypeToDelete: productFileTypes.GREEN_FLAME_LABEL_SEVEN,
        };
    }
}

export default {
    parseProductFormData,
};
