import { LABEL_INPUT_PERFORMANCE_FACTOR } from '../../form_labels';
import { LABEL_COEFFICIANT_DE_PERFORMANCE } from '../../product-constants';

export default [
    {
        property: LABEL_COEFFICIANT_DE_PERFORMANCE,
        label: LABEL_INPUT_PERFORMANCE_FACTOR,
        type: 'number',
        required: true,
    },
];
