export const LABEL_EPAISSEUR = 'épaisseur (mm)';
export const LABEL_RESISTANCE = 'La résistance thermique R';
export const LABEL_CLASSE = 'Classe de l’isolant selon la norme NF EN 12 828+A1:2014';

export const LABEL_TYPE_FONCTIONNEMENT = 'type de fonctionnement';
export const LABEL_EFFICACITE_ENERGETIQUE = "L'efficacité énergétique saisonnière (η s) selon le règlement (EU) n°813/2013 de la commission du 2 août 2013";
export const LABEL_CLASSE_REGULATEUR =
    'classe du régulateur (IV à VIII) telle que définie au paragraphe 6.1 de la communication de la Commission 2014/C 207/02 dans le cadre du règlement (UE) n°813/2013';
export const LABEL_REFERENCE_REGULATEUR = 'référence du régulateur';
export const LABEL_MARQUE_REGULATEUR = 'marque du régulateur';
export const LABEL_SEASONAL_PERFORMANCE_COEFFICIENT = 'Le coefficient de performance saisonnier (SCOP) selon le règlement n°206/2012 de la commission du 6 mars 2012';
export const LABEL_NOMINAL_POWER = 'La puissance nominale de la PAC (en kW) selon le règlement n°206/2012 de la commission du 6 mars 2012';

export const LABEL_FACTEUR_SOLAIRE = 'Le facteur solaire Sw';
export const LABEL_TYPE_DE_CONSTITUTION = 'type de constitution de la toiture';

export const LABEL_COEFFICIANT_DE_TRANSMISSION = 'Le coefficient de transmission surfacique Uw';

export const LABEL_PUISSANCE_THERMIQUE = 'La puissance thermique nominale P';

export const LABEL_CLASSE_ENERGETIQUE_VENTILATION = 'classe énergétique du système de ventilation selon le règlement européen (UE) n°1254/2014';
export const LABEL_TYPE_CAISSON = 'type de caisson';
export const LABEL_MARQUE_CAISSON = 'marque du caisson';
export const LABEL_REFERENCE_CAISSON = 'référence du caisson';
export const LABEL_MARQUE_BOUCHES_EXTRACTION = "marque des bouches d'extraction";
export const LABEL_REFERENCE_BOUCHES_EXTRACTION = "référence des bouches d'extraction";
export const LABEL_REFERENCE_AVIS_TECHNIQUE = "référence de l’Avis Technique (CCFAT ou autres organismes d'accréditation)";
export const LABEL_DATE_VALIDITE_AVIS_TECHNIQUE = "date de validité de l’Avis Technique (CCFAT ou autres organismes d'accréditation)";
export const LABEL_LA_POMPE_A_CHALEUR_EST_DE_TYPE_AIR_EAU =
    'La pompe à chaleur est de type air/eau comportant un dispositif d’appoint utilisant un combustible liquide ou gazeux et une régulation qui les pilote';

export const LABEL_MARQUE_BOUCHE_AIR = "marque des bouches d'entrées d'air";
export const LABEL_REFERENCE_BOUCHE_AIR = "référence des bouches d'entrées d'air";
export const LABEL_PUISSANCE_ELECTRIQUE = 'La puissance électrique absorbée pondérée';
export const LABEL_MODELE_VENTILATION = 'Modèle de ventilation';
export const LABEL_TYPE_VENTILATION = 'Type de ventilation mécanique contrôlée';

export const LABEL_COEFFICIANT_DE_PERFORMANCE = "Le coefficient de performance (COP) de l'équipement conformément aux conditions de la norme EN 16147";
export const HYBRID_SOLAR_CAPTORS = 'Les capteurs solaires sont des capteurs hybrides';
export const SOLAR_CERTIFICATION_LABEL = 'Certification CSTBat ou Solar Keymark ou équivalente des capteurs solaires';
export const LABEL_NATURE_FLUID_CAPTORS = 'Nature du fluide circulant dans les capteurs';
export const LABEL_ROOF_ONLY_CONSTITUTION_TYPE = 'Un système de toiture assurant à lui seul le facteur solaire requis';
export const LABEL_ROOF_ONLY_CONSTITUTION_TYPE_VALUE = 'La toiture est constituée d’un système de toiture assurant à lui seul le facteur solaire requis';
export const LABEL_SET_OF_ELEMENTS_CONSTITUTION_TYPE = 'Un ensemble d’éléments dont la composition permet d’obtenir le facteur solaire requis';
export const LABEL_SET_OF_ELEMENTS_CONSTITUTION_TYPE_VALUE = 'Mise en place d’éléments séparés dont la composition permet d’obtenir le facteur solaire requis';

export const PRODUCT_UNDER_VERIFICATION = 'Ce produit est en cours de vérification';

export const LABEL_GREEN_FLAME_LABEL_SEVEN = 'Label flamme verte 7*';
export const LABEL_NOMINAL_YIELD = 'Rendement nominal';
export const LABEL_PARTICLE_EMISSION = 'Emissions de particules';
export const LABEL_CARBON_MONOXIDE = 'Monoxyde de carbone (CO)';
export const LABEL_NITROGEN_OXIDE_EMISSIONS = "Emissions d'oxyde d'azote (Nox)";
