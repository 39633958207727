export const INVALID_DOSSIER_CEE_QUOTATION = 'À signer par le client';
export const INVALID_DOSSIER_CEE_INVOICE = 'À créer et à finaliser';
export const INVALID_DOSSIER_CEE_POST_RENOVATION_REPORT = 'À ajouter';
export const INVALID_DOSSIER_CEE_SWORN_STATEMENT = "Générée par l'application : à signer (client et installateur)";
export const INVALID_DOSSIER_CEE_INVOICE_TO_FINALIZE = 'À finaliser et à envoyer';
export const INVALID_DOSSIER_CEE_SIZING_NOTE = 'À ajouter';

export const MANDATORY_DOSSIER_CEE_DOCUMENT = 'À ajouter';

export const VALID_DOSSIER_CEE_QUOTATION = 'Signé par le client';
export const VALID_DOSSIER_CEE_INVOICE_TO_SEND = 'À envoyer au client';
export const VALID_DOSSIER_CEE_INVOICE = 'Adressée au client';

export const DOSSIER_CEE_CANCELLED_DOCUMENT = 'Annulée';
export const DOSSIER_CEE_RECTIFIED_INVOICE = 'Rectifiée';

export const DOSSIER_CEE_QUOTATION_LABEL = 'Devis des travaux';
export const DOSSIER_CEE_CADRE_CONTRIBUTION_LABEL = 'Cadre(s) de contribution';
export const DOSSIER_CEE_INVOICE_LABEL = 'Facture des travaux';
export const DOSSIER_CEE_POST_RENOVATION_REPORT_LABEL = 'Rapport post-travaux';
export const DOSSIER_CEE_SIZING_NOTE_LABEL = 'Note de dimensionnement';
export const DOSSIER_CEE_SWORN_STATEMENT_LABEL = "Attestation sur l'honneur";
export const DOSSIER_CEE_CADASTRAL_PLOT_LABEL = 'Parcelle cadastrale';
export const DOSSIER_CEE_SITE_PHOTO_LABEL = 'Photo du site';
export const DOSSIER_CEE_OTHER_DOCUMENT_LABEL = 'Autre';
export const DOSSIER_CEE_SOLAR_CERTIFICATION_LABEL = 'Certification des capteurs solaires';
export const DOSSIER_CEE_TAX_NOTICES_LABEL = "Avis d'imposition";
export const DOSSIER_CEE_SOLAR_FACTOR_CALCULATION_NOTE_LABEL = 'Note de calcul du facteur solaire';
export const DOSSIER_CEE_DOMICILIATION_CERTIFICATE_LABEL = 'Attestation de domiciliation';
export const DOSSIER_CEE_GREEN_FLAME_LABEL_SEVEN = 'Label flamme verte 7*';

export const NO_DOSSIERS_CEE = "Aucun dossier de financement n'a été créé.";

export const DOSSIER_CEE_NOT_SENDABLE = "Merci de compléter votre dossier avant de pouvoir l'envoyer.";
export const DOSSIER_CEE_NOT_DOWNLOADABLE = 'Merci de compléter votre dossier avant de pouvoir le télécharger.';

export const NO_COMPLETED_DOSSIERS_CEE = 'Aucun dossier de financement complété.';
export const NO_SENT_DOSSIERS_CEE = 'Aucun dossier de financement envoyé.';

export const DOSSIER_CEE_TAX_NOTICES_REGENERATED = "Les avis d'imposition ont bien été générés.";

export const DOSSIER_CEE_CANCELLED_BY_BACK_OFFICE = 'Le dossier a été annulé à cause d’un problème administratif. Il ne peut pas être envoyé ni téléchargé.';
