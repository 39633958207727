import { CREATE_UPLOAD_DOCUMENT_SUCCESS, GET_UPLOAD_DOCUMENTS_SUCCESS, GET_UPLOAD_DOCUMENT_SUCCESS, DELETE_UPLOAD_DOCUMENT_SUCCESS } from '../types/upload-document.types';

function initState() {
    return {
        uploadDocuments: [],
    };
}

export default function (state = initState(), action) {
    switch (action.type) {
        case CREATE_UPLOAD_DOCUMENT_SUCCESS:
            return {
                ...state,
                uploadDocuments: [action.payload.uploadDocument, ...state.uploadDocuments],
            };

        case GET_UPLOAD_DOCUMENTS_SUCCESS:
            return {
                ...state,
                uploadDocuments: action.payload.uploadDocuments,
            };

        case GET_UPLOAD_DOCUMENT_SUCCESS:
            return {
                ...state,
                uploadDocument: action.payload.uploadDocument,
            };

        case DELETE_UPLOAD_DOCUMENT_SUCCESS:
            return action.payload.goBackToDocumentsPage
                ? state
                : {
                      uploadDocuments: state.uploadDocuments.filter(({ uuid }) => uuid !== action.payload.uploadDocument.uuid),
                  };

        default:
            return state;
    }
}
