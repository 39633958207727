import { v1 as uuidV1 } from 'uuid';

import entitiesUtils from './entities-utils';
import {
    customerTypes,
    quotationStatuses,
    quotationStatusesLabels,
    quotationStatusesActionButtonLabels,
    organizationInsuranceWarrantyAreas,
    applicationPages,
    organizationInsuranceWarrantyAreasTranslations,
    productTypes,
    productCategoriesValues as productCategories,
} from './enums';
import {
    QUOTATION_NOT_DELETABLE,
    QUOTATION_NOT_INVOICEABLE,
    QUOTATION_NOT_SENDABLE_AND_SIGNED,
    QUOTATION_NOT_SENDABLE_AND_INVOICED,
    QUOTATION_NOT_SENDABLE_NOR_PRINTABLE,
    QUOTATION_NOT_DELETABLE_AND_INVOICED,
    QUOTATION_NOT_INVOICEABLE_AND_INVOICED,
    QUOTATION_NOT_ADDABLE_OPERATIONS,
    QUOTATION_NOT_SENDABLE_BECAUSE_OF_EXPIRATION,
} from './messages';
import { isNonEmptyObject, isValidValue } from '.';
import history from './history';
import { formatQuotationContractSelectorName } from '../components/core/contracts/contract/amendments/amendment_modal/amendment-helper';
import dateUtils from './date_utils/moment/index';
import operationSheetNames from './operation-sheet-names';
import productFormHelper from './product-form-helper';
import operationHelper from './operation-helper';

function getCustomerLabel(customer, { emptyStringAsDefault = false, includeLastName = false } = {}) {
    const { customerType, customerData } = customer;
    if (!customerData) return emptyStringAsDefault ? '' : 'Non renseigné';

    const { fullName, name, lastName } = customerData;
    const customerLabelStrategies = {
        [customerTypes.CUSTOMER_PP]: () => (includeLastName ? lastName : fullName),
        [customerTypes.CUSTOMER_PM]: () => name,
    };
    const strategy = customerLabelStrategies[customerType];

    return strategy ? strategy() : '';
}

function formatOperationSheetName(operationSheetName) {
    const nameParts = operationSheetName.replace(/_/g, '-').split('V-');
    const operationName = nameParts[0];
    let version = nameParts[1];
    let formattedSuffix = '';
    if (version.includes('-CDP')) {
        version = version.replace('-CDP', '');
        formattedSuffix += ' - (Coup de pouce)';
    }
    version = version.replace('-', '.');

    return `${operationName} (v. ${version})${formattedSuffix}`;
}

function getOperationsNames(operations = []) {
    return operations.map(({ operationSheetName }) => ({
        key: uuidV1(),
        name: formatOperationSheetName(operationSheetName),
    }));
}

function getStatusLabel(status) {
    return quotationStatusesLabels[status];
}

function getActionButtonLabel(status) {
    return quotationStatusesActionButtonLabels[status];
}

function formatQuotationsForTable(quotations = []) {
    return quotations.map((quotation) => ({
        key: quotation.uuid,
        uuid: quotation.uuid,
        quotationNumber: quotation.quotationNumber,
        customer: getCustomerLabel(quotation.customer),
        quotationIssueDate: quotation.quotationIssueDate,
        operations: getOperationsNames(quotation.operations),
        energySavingCertificatePremium: quotation.energySavingCertificatePremium,
        totalPriceToPay: quotation.totalPriceToPay,
        totalNetPriceToPay: quotation.totalNetPriceToPay,
        hasAdministrativeProblem: quotation.hasAdministrativeProblem,
        status: quotation.status,
        cadreContribution: quotation.cadreContribution,
    }));
}

function formatQuotationIssueDate(quotationIssueDate) {
    return entitiesUtils.formatDate(quotationIssueDate);
}

function filterQuotationsByStatus(quotations = [], status) {
    return quotations.filter((quotation) => quotation.status === status);
}

function filterDraftQuotations(quotations) {
    return filterQuotationsByStatus(quotations, quotationStatuses.DRAFT);
}

function filterSentQuotations(quotations) {
    return filterQuotationsByStatus(quotations, quotationStatuses.SENT);
}

function filterSignedQuotations(quotations) {
    return filterQuotationsByStatus(quotations, quotationStatuses.SIGNED);
}

function changeEditionPathNameToPreviewPathname(pathname) {
    return pathname.replace('edition', 'apercu');
}

function getCustomerNumber(customer) {
    return customer.customerNumber || 'Non sélectionné';
}

function getWarrantyAreaLabel(warrantyArea) {
    const warrantyAreaPrefix = warrantyArea === organizationInsuranceWarrantyAreas.DOM_TOM ? 'les ' : 'la ';

    return warrantyAreaPrefix.concat(organizationInsuranceWarrantyAreasTranslations[warrantyArea]);
}

function isSameCustomer(customer, { customerId, customerType }) {
    return customer.customerId === customerId && customer.customerType === customerType;
}

function getRenovationAddressesOptions(renovationAddresses = []) {
    return renovationAddresses.map((renovationAddress) => ({
        label: entitiesUtils.getFullAddressText(renovationAddress),
        value: renovationAddress._id,
    }));
}

function isSameRenovationAddress(address, { renovationAddressId }) {
    return address.renovationAddressId === renovationAddressId;
}

function isSameBuildingAgeValue(address, { buildingAge }) {
    return address.buildingAge === buildingAge;
}

function getSelectContractPlaceholder({ blockValidity, reference, delegateeName, contractAmendments }) {
    if (!blockValidity.validated) return '';
    if ([blockValidity.valid, reference, delegateeName].some((value) => !value)) return '';

    return formatQuotationContractSelectorName({ reference, delegateeName, contractAmendments });
}

function getSelectContractOptions(contractDocs) {
    if (!contractDocs) return [];
    const { docs: contracts = [] } = contractDocs;

    return contracts.map((contract) => ({
        label: formatSelectContractOption(contract),
        value: contract._id,
    }));
}

function formatSelectContractOption(contract) {
    return formatQuotationContractSelectorName(contract);
}

function isSameContract(contract, { contractId }) {
    return contract.blockValidity.valid && contract.contractId === contractId;
}

function isQuotationValid(quotation) {
    const { customer, address, contract, operations } = quotation;
    let valid = customer.blockValidity.valid && address.blockValidity.valid;
    if (contract.blockValidity.validated) valid = contract.blockValidity.valid;
    operations.forEach((operation) => {
        valid = valid && operation.blockValidity.valid;

        const { products } = operation;
        products.forEach((product) => {
            valid = valid && product.blockValidity.valid;
        });
    });

    return valid;
}

function hasAFileDownloadUrl(quotation) {
    return quotation.fileDownloadUrl;
}

function getTotalPriceWithoutTax(quotation) {
    const { pricesPerVatRate = [] } = quotation;

    return pricesPerVatRate.reduce((accumulator, { totalPreTax }) => accumulator + totalPreTax, 0);
}

function getVatRates(quotation) {
    const { pricesPerVatRate = [] } = quotation;

    return pricesPerVatRate.map(({ vatRate, totalTax = 0 }) => ({
        key: uuidV1(),
        label: `TVA à ${vatRate * 100}%`,
        value: totalTax,
    }));
}

function isCustomerPM(customerType) {
    return customerType === customerTypes.CUSTOMER_PM;
}

function isCommercialDiscountPercentageDisabled(quotation) {
    return quotation.totalPriceWithoutTax === 0 && quotation.commercialDiscount === 0;
}

function canShowCustomerEnergySavingCertificatePremium(quotation) {
    const { contract, energySavingCertificatePremium } = quotation;

    return contract.blockValidity.validated && contract.blockValidity.valid && energySavingCertificatePremium > 0;
}

function canShowCustomerEnergySavingCertificatePremiumForOperation(operation) {
    const { operationVolumesAndPremium } = operation;

    return isNonEmptyObject(operationVolumesAndPremium) && operationVolumesAndPremium.premium > 0;
}

function getCustomerEnergySavingCertificatePremiumLabel(operation) {
    if (operationHelper.isCDPOperation(operation.operationSheetName)) {
        return `Vous devez reverser au minimum ${entitiesUtils.formatPrice(operation.customerMinimumEnergySavingCertificatePremium)}.`;
    }
    return `Vous pouvez reverser entre ${entitiesUtils.formatPrice(operation.customerMinimumEnergySavingCertificatePremium)} et ${entitiesUtils.formatPrice(
        operation.operationVolumesAndPremium.premium
    )}.`;
}

function canShowTotalVolume(quotation) {
    return getOperationsTotalVolume(quotation) > 0;
}

function getOperationsTotalVolume(target) {
    const { operations = [] } = target;

    return operations
        .filter((op) => op.compliant)
        .reduce((accumulator, operation) => {
            const { operationVolumesAndPremium = {} } = operation;
            return accumulator + operationVolumesAndPremium.volumeTotal || 0;
        }, 0);
}

function getFormattedQuotationTotalVolume(quotation) {
    return entitiesUtils.formatVolume(getOperationsTotalVolume(quotation));
}

function canShowEnergySavingCertificatePremium(quotation) {
    return quotation.energySavingCertificatePremium > 0;
}

const isQuotationExpirationDateValid = ({ quotationExpirationDate }) => quotationExpirationDate && dateUtils.isSameOrAfter(quotationExpirationDate);

function getQuotationSendButtonMessage(quotation) {
    if (!isQuotationExpirationDateValid(quotation)) return QUOTATION_NOT_SENDABLE_BECAUSE_OF_EXPIRATION;

    return isQuotationSendable(quotation)
        ? ''
        : isQuotationInvoiced(quotation)
        ? QUOTATION_NOT_SENDABLE_AND_INVOICED
        : isQuotationSigned(quotation)
        ? QUOTATION_NOT_SENDABLE_AND_SIGNED
        : QUOTATION_NOT_SENDABLE_NOR_PRINTABLE;
}

function isQuotationSendable(quotation) {
    return [quotationStatuses.COMPLETED, quotationStatuses.SENT].includes(quotation.status) && isQuotationExpirationDateValid(quotation) && allProductsOfQuotationAreConfirmed(quotation);
}

function isQuotationSigned(quotation) {
    return [quotationStatuses.SIGNED, quotationStatuses.SIGNED_AND_FINALIZED_RELATED_INVOICE].includes(quotation.status);
}

function getQuotationPrintButtonMessage(quotation) {
    return isQuotationPrintable(quotation) ? '' : QUOTATION_NOT_SENDABLE_NOR_PRINTABLE;
}

function isQuotationPrintable(quotation) {
    return !!quotation.fileDownloadUrl && quotation.status !== quotationStatuses.DRAFT && allProductsOfQuotationAreConfirmed(quotation);
}

function canShowBackToEditionNotification(quotation = {}) {
    return [quotationStatuses.DRAFT, quotationStatuses.COMPLETED].includes(quotation.status);
}

function getQuotationInvoiceButtonMessage(quotation = {}) {
    return isQuotationInvoiceable(quotation) ? '' : isQuotationInvoiced(quotation) ? QUOTATION_NOT_INVOICEABLE_AND_INVOICED : QUOTATION_NOT_INVOICEABLE;
}

function getQuotationDeleteButtonMessage(quotation = {}) {
    return isQuotationDeletable(quotation) ? '' : isQuotationInvoiced(quotation) ? QUOTATION_NOT_DELETABLE_AND_INVOICED : QUOTATION_NOT_DELETABLE;
}

function isQuotationInvoiceable(quotation = {}) {
    return quotation.status === quotationStatuses.SIGNED;
}

function isQuotationRefusable({ status }) {
    return [quotationStatuses.DRAFT, quotationStatuses.COMPLETED, quotationStatuses.SENT].includes(status);
}

function isQuotationDeletable(quotation = {}) {
    return [quotationStatuses.DRAFT, quotationStatuses.COMPLETED, quotationStatuses.EXPIRED, quotationStatuses.REFUSED].includes(quotation.status);
}

function isQuotationSent(quotation = {}) {
    return quotation.status === quotationStatuses.SENT;
}

function isQuotationInvoiced(quotation = {}) {
    return quotation.status === quotationStatuses.SIGNED_AND_FINALIZED_RELATED_INVOICE;
}

function getSelectOperationTechnicianMessage(quotationOperation) {
    const { valid, compliant, errorMessages } = quotationOperation.technician.blockValidity;

    return valid && compliant ? '' : errorMessages.length > 0 ? errorMessages[0] : '';
}

function doesQuotationHaveAtLeatOneCompliantOperation(quotation) {
    const { operations = [] } = quotation;

    return doesOperationsIncludeAtLeastOneCompliantOnes(operations);
}

function doesOperationsIncludeAtLeastOneCompliantOnes(operations) {
    return operations.reduce((atLeastOneOperationIsCompliant, { compliant = false }) => {
        return atLeastOneOperationIsCompliant || compliant;
    }, false);
}

function canShowTotalPreTaxWithCommercialDiscountField(quotation) {
    return quotation.commercialDiscount > 0;
}

function getTotalPreTaxWithCommercialDiscount(quotation) {
    return quotation.totalPriceWithoutTax - quotation.commercialDiscount;
}

function handleOpenQuotation({ uuid, status }) {
    const openQuotationStrategies = {
        [quotationStatuses.DRAFT]: handleCompleteQuotation,
        [quotationStatuses.COMPLETED]: handleCompleteQuotation,
        [quotationStatuses.SENT]: handleConsultQuotation,
        [quotationStatuses.SIGNED]: handleConsultQuotation,
        [quotationStatuses.SIGNED_AND_FINALIZED_RELATED_INVOICE]: handleConsultQuotation,
        [quotationStatuses.EXPIRED]: handleCompleteQuotation,
        [quotationStatuses.REFUSED]: handleConsultQuotation,
    };
    openQuotationStrategies[status](uuid);
}

function handleCompleteQuotation(uuid) {
    history.push(`/devis/${uuid}/edition`);
}

function handleConsultQuotation(uuid) {
    history.push(`/devis/${uuid}/apercu`);
}

function canShowCustomerColumn(targetPage) {
    return targetPage !== applicationPages.CUSTOMER;
}

function canShowTableMenuActions(targetPage) {
    return targetPage === applicationPages.QUOTATIONS;
}

function filterQuotationsWithVatCertificateAndFormatForTable(quotations = []) {
    return quotations
        .filter(({ vatCertificate }) => isValidValue(vatCertificate?.fileDownloadUrl))
        .map((quotation) => ({
            key: quotation.uuid,
            uuid: quotation.uuid,
            quotationNumber: quotation.quotationNumber,
            customer: getCustomerLabel(quotation.customer),
            quotationIssueDate: quotation.quotationIssueDate,
            operations: getOperationsNames(quotation.operations),
            vatCertificate: quotation.vatCertificate,
        }));
}

function getOperationSheetNames(operations) {
    if (operations) return operations.map((operation) => operation.operationSheetName);
}

function canNewOperationBeAdded({ operations }) {
    return operations.some((operation) => {
        return !operation.operationSheetName;
    });
}

function getAddNewOperationToolTipMessage(quotation) {
    return canNewOperationBeAdded(quotation) ? QUOTATION_NOT_ADDABLE_OPERATIONS : '';
}

function extractSolarFactorCalculationNote(quotation) {
    if (isNonEmptyObject(quotation)) {
        const roofMainProduct = extractRoofMainProduct(quotation);

        return roofMainProduct?.solarFactorCalculationNote || {};
    }

    return {};
}

function extractRoofMainProduct(quotation) {
    const quotationProduct = quotation.operations
        .filter(({ operationSheetName }) => operationSheetName === operationSheetNames.BAT_EN_109)
        .flatMap(({ products }) => products)
        .find(({ product }) => product && product.type === productTypes.MAIN_PRODUCT && product.category === productCategories.TOITURE);

    return quotationProduct && quotationProduct.product;
}

const isWasteManagementValid = (quotation) => quotation?.wasteManagement?.blockValidity?.valid;

function isSelectContractEnabled(quotation) {
    const { operations = [] } = quotation;

    return areAllOperationsValid(operations) && doesOperationsIncludeAtLeastOneCompliantOnes(operations) && isWasteManagementValid(quotation) && allProductsOfQuotationAreConfirmed({ operations });
}

function areAllOperationsValid(operations) {
    return operations.length > 0
        ? operations.reduce((allOperationsValid, { products = [], blockValidity }) => {
              return allOperationsValid && blockValidity.valid && areAllOperationProductsValid(products);
          }, true)
        : false;
}

function areAllOperationProductsValid(operationProducts = []) {
    return operationProducts.length > 0
        ? operationProducts.reduce((allProductsValid, { blockValidity }) => {
              return allProductsValid && blockValidity.valid;
          }, true)
        : false;
}

function isQuotationExpired(quotation) {
    return isNonEmptyObject(quotation) && quotation.status === quotationStatuses.EXPIRED && isValidValue(quotation.expiredAt);
}

function allProductsOfQuotationAreConfirmed(quotation = {}) {
    const { operations = [] } = quotation;
    return operations.every((operation) => {
        const { products = [] } = operation;
        return products.every(({ product }) => productFormHelper.isProductWithValidStatus(product));
    });
}

export default {
    formatQuotationsForTable,
    filterDraftQuotations,
    filterSentQuotations,
    getStatusLabel,
    getActionButtonLabel,
    changeEditionPathNameToPreviewPathname,
    getCustomerLabel,
    getCustomerNumber,
    getWarrantyAreaLabel,
    isSameCustomer,
    getRenovationAddressesOptions,
    isSameRenovationAddress,
    isSameBuildingAgeValue,
    getSelectContractPlaceholder,
    getSelectContractOptions,
    isSameContract,
    isQuotationValid,
    getTotalPriceWithoutTax,
    getVatRates,
    getOperationsNames,
    formatOperationSheetName,
    isCustomerPM,
    hasAFileDownloadUrl,
    isCommercialDiscountPercentageDisabled,
    canShowCustomerEnergySavingCertificatePremium,
    canShowTotalVolume,
    getOperationsTotalVolume,
    canShowEnergySavingCertificatePremium,
    getCustomerEnergySavingCertificatePremiumLabel,
    getFormattedQuotationTotalVolume,
    isQuotationSendable,
    getQuotationSendButtonMessage,
    isQuotationPrintable,
    getQuotationPrintButtonMessage,
    canShowBackToEditionNotification,
    getQuotationInvoiceButtonMessage,
    getQuotationDeleteButtonMessage,
    isQuotationInvoiceable,
    isQuotationDeletable,
    isQuotationSent,
    filterSignedQuotations,
    formatQuotationIssueDate,
    getSelectOperationTechnicianMessage,
    doesQuotationHaveAtLeatOneCompliantOperation,
    canShowTotalPreTaxWithCommercialDiscountField,
    getTotalPreTaxWithCommercialDiscount,
    canShowCustomerEnergySavingCertificatePremiumForOperation,
    handleOpenQuotation,
    canShowTableMenuActions,
    canShowCustomerColumn,
    filterQuotationsWithVatCertificateAndFormatForTable,
    getOperationSheetNames,
    formatSelectContractOption,
    getAddNewOperationToolTipMessage,
    canNewOperationBeAdded,
    extractSolarFactorCalculationNote,
    isSelectContractEnabled,
    isQuotationExpired,
    isQuotationRefusable,
};
