import { DIFFERENT_PASSWORDS } from '../messages';

export default [
    {
        property: 'password',
        label: 'Mot de passe',
        type: 'string',
        required: true,
    },
    {
        property: 'confirmPassword',
        label: 'Confirmation du mot de passe',
        type: 'string',
        required: true,
        validate: ({ password, confirmPassword }, formErrors) => {
            const samePasswords = password === confirmPassword;
            if (!samePasswords) formErrors.password = DIFFERENT_PASSWORDS;

            return samePasswords;
        },
    },
];
