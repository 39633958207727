export const LABEL_UN_PARE_VAPEUR =
    "Un pare-vapeur ou tout autre dispositif permettant d'atteindre un résultat équivalent est mis en place, lorsqu'il est nécessaire de protéger les matériaux d'isolation thermique contre les transferts d'humidité pour garantir la performance de l'ouvrage.";
export const LABEL_AMENAGEMENTS_NECESSAIRES = "Aménagements nécessaires à la mise en place de l'isolation";
export const LABEL_ISOLATION_THERMIQUE = "L'isolation thermique réalisée a nécessité la mise en place d'un pare-vapeur ou tout autre dispositif permettant d'atteindre un résultat équivalent";
export const LABEL_TRAVAUX_EN_COMBLES_CHAUFFES = 'Travaux en combles chauffés et finitions réalisées.';

export const LABEL_ENERGIE_DE_CHAUFFAGE = 'énergie de chauffage';
export const LABEL_HEATING_TYPE_BEFORE_INSTALLATION = 'énergie de chauffage avant travaux';
export const LABEL_HEATING_TYPE_AFTER_INSTALLATION = 'énergie de chauffage après travaux';

export const LABEL_OPERATION_NE_CORRESPOND =
    "L’opération ne correspond ni à l'installation de fenêtres dans les parties communes non chauffées du bâtiment, ni à la fermeture d'une loggia par des parois vitrées, ni à la construction d’une véranda à parois vitrées, ni à la création d’une ouverture dans une paroi opaque, ni au remplacement de vitrages sur une fenêtre ou porte-fenêtre existante.";

export const LABEL_BATIMENT_RESIDENTIEL = 'Bâtiment résidentiel en France d’outre-mer';
export const LABEL_A_LA_REUNION = 'A La Réunion, le bâtiment ne se situe pas dans une zone au-dessus de 600 m d’altitude.';

export const LABEL_LA_POMPE_CHALEUR_EST_ASSOCIEE = 'La pompe à chaleur est associée à une chaudière à haute performance énergétique pour le chauffage des locaux';
export const LABEL_LA_POMPE_CHALEUR_EST_UTILISEE = "La pompe à chaleur est utilisée uniquement pour le chauffage de l'eau chaude sanitaire.";

export const LABEL_EQUIPEMENT_INSTALLE = 'Équipement installé';
export const LABEL_EQUIPEMENT_REMPLACE_CHAUDIERE_CHARBON_OU_GAZ = "L'équipement installé vient en remplacement d'une chaudière individuelle au charbon ou au gaz, autre qu'à condensation.";
export const LABEL_EQUIPEMENT_REMPLACE_CHAUDIERE_FIOUL = "L'équipement installé vient en remplacement d'une chaudière individuelle au fioul.";

export const LABEL_SURFACE_PAC = 'surface chauffée par la PAC installée';

export const LABEL_SURFACE_HABITABLE = 'surface habitable';
export const LABEL_NOMBRE_DE_LOGEMENTS_EQUIPES = "nombre de logements équipées d'une chaudière individuelle";

export const LABEL_TYPE_DE_VENTILLATION = 'Type de ventillation mécanique contrôlée';
export const LABEL_NOMBRE_DE_LOGEMENTS_DESSERVIS = 'nombre de logements desservis';

export const LABEL_LA_POMPE_A_CHALEUR_EST_UTILISEE = 'La pompe à chaleur est utilisée uniquement pour le chauffage de l’eau chaude sanitaire';
export const LABEL_LA_POMPE_A_CHALEUR_EST_CONCUE = 'La pompe à chaleur est conçue pour fonctionner à basse température';

export const LABEL_OPERATION_REALISEE_SUR_UN_RESEAU =
    "L’opération est réalisée sur un réseau hydraulique de chauffage ou d’eau chaude sanitaire existant depuis plus de 2 ans à la date d'engagement de l'opération";
export const LABEL_NON_ISOLE = 'non isolé';
export const LABEL_DONT_ISOLATION = 'dont l’isolation en place est de classe inférieure ou égale à 2';
export const LABEL_INSTALLATION_DE_CHAUFFAGE_COLLECTIF = "L'installation de chauffage collectif ou de production d'eau chaude sanitaire a été remplacée après le 1er janvier 2018.";

export const LABEL_OPERATION_ENGAGEE = 'L’opération est engagée moins de douze mois suivant l’engagement d’une opération portant sur un même bâtiment et un même bénéficiaire';

export const LABEL_BATIMENT_TERTIAIRE = 'Bâtiment tertiaire en France d’outre-mer';
export const LABEL_BUILDING_TOTAL_AREA = 'Surface totale du bâtiment (m²)';

export const LABEL_OPERATION_INCLUT_APPLICATION = "L’opération inclut l'application de peintures réfléchissantes sur la toiture";

export const LABEL_ISOLANT_EST_DE_CLASSE_SUP_3 = 'L’isolant est de classe supérieure ou égale à 3 selon la norme NF EN 12 828+A1:2014';
export const LABEL_ISOLANT_EST_DE_CLASSE_SUP_4 = 'L’isolant est de classe supérieure ou égale à 4 selon la norme NF EN 12 828+A1:2014';

export const LABEL_INSTALLATION_FINISHES = {
    DEFAULT: "Finitions d'installation",
    CUSTOM: "Autres finitions d'installation",
    QUOTATION: "Finitions proposées permettant d'assurer la fixation satisfaisante et la protection de l'isolant",
    INVOICE: "Finitions réalisées permettant d'assurer la fixation satisfaisante et la protection de l'isolant",
};

export const LABEL_EQUIPEMENT_REMPLACE_POELE_CHARBON = "L'appareil à installer vient remplacer un poêle à charbon";
