import { getRandomArrayValue } from './array.helper';
import { customerTypes, operationHeatingTypes, renovationAddressBuildingSectors, quotationTechnicianTypes, operationInstallationFinishesTranslations } from './enums';
import { LABEL_EQUIPEMENT_REMPLACE_CHAUDIERE_FIOUL, LABEL_EQUIPEMENT_REMPLACE_CHAUDIERE_CHARBON_OU_GAZ } from './operation-constants';
import operationSheetNames from './operation-sheet-names';
import { isNullishOrEmpty } from '.';

function formatOperationFieldLabel(label = '') {
    const cleanedLabel = label.replace(/\W$/, '').trim();

    return cleanedLabel.charAt(0).toUpperCase() + cleanedLabel.substring(1);
}

function hasOperationChanged(operation = {}, operationSheetName) {
    return operation.operationSheetName && operation.operationSheetName !== operationSheetName;
}

function getOperationSheetNameLabel({ name, title } = {}) {
    if (!name || !title) return '';

    const nameParts = name.replace(/_/g, '-').split('V-');
    const operationName = nameParts[0];
    let version = nameParts[1];
    let formattedTitle = title;
    if (version.includes('-CDP')) {
        version = version.replace('-CDP', '');
        formattedTitle += ' (Coup de pouce)';
    }
    version = version.replace('-', '.');

    return `${operationName} (v. ${version}) - ${formattedTitle}`;
}

function doesOperationMatchAddressBuildingSector(operationSheetName, { buildingSector }) {
    if (!buildingSector) return true;
    if (operationSheetName.includes('BAT')) return buildingSector === renovationAddressBuildingSectors.TERTIARY;

    return buildingSector === renovationAddressBuildingSectors.RESIDENTIAL;
}

function filterOutCdpOperationsForCustomerPM(operationSheetName, customer) {
    if (customer.customerType === customerTypes.CUSTOMER_PM && operationSheetName.includes('_CDP')) {
        return;
    }
    return operationSheetName;
}

function getOperationSheetsOptions(operationSheets, address, customer) {
    return operationSheets
        .filter(({ name }) => doesOperationMatchAddressBuildingSector(name, address))
        .filter(({ name }) => filterOutCdpOperationsForCustomerPM(name, customer))
        .map((operationSheet) => ({
            label: operationSheet.formattedOperationSheetName,
            value: operationSheet,
        }));
}

function getOperationSelectPlaceholder(operationSheets = [], operationSheetName) {
    const operationSheet = operationSheets.find(({ name }) => name === operationSheetName);
    if (!operationSheet) return '';

    return getOperationSheetNameLabel(operationSheet);
}

function getHeatingTypesOptions() {
    return [
        { label: 'Électricité', value: operationHeatingTypes.ELECTRICITE },
        { label: 'Combustible', value: operationHeatingTypes.COMBUSTIBLE },
    ];
}

function getRadioGroupYesOrNoOptions() {
    return [
        { label: 'Oui', value: true },
        { label: 'Non', value: false },
    ];
}

function getEquipementInstalleOptions() {
    return [
        { label: LABEL_EQUIPEMENT_REMPLACE_CHAUDIERE_CHARBON_OU_GAZ, value: LABEL_EQUIPEMENT_REMPLACE_CHAUDIERE_CHARBON_OU_GAZ },
        { label: LABEL_EQUIPEMENT_REMPLACE_CHAUDIERE_FIOUL, value: LABEL_EQUIPEMENT_REMPLACE_CHAUDIERE_FIOUL },
    ];
}

function getOperationSheetUnitLabel(operationSheetName) {
    const operationSheetUnits = {
        SQUARE_METER: [
            'BAR_EN_101V_A33_3_CDP',
            'BAR_EN_101V_A33_3',
            'BAR_EN_101V_A39_4_CDP',
            'BAR_EN_101V_A39_4',
            'BAR_EN_102V_A14_1',
            'BAR_EN_102V_A39_2',
            operationSheetNames.BAR_EN_103V_A36_4_CDP,
            operationSheetNames.BAR_EN_103V_A36_4,
            operationSheetNames.BAR_EN_103V_A39_5_CDP,
            operationSheetNames.BAR_EN_103V_A39_5,
            'BAR_EN_106V_A33_4',
            'BAR_EN_107V_A20_3',
            'BAT_EN_101V_A33_3',
            'BAT_EN_102V_A27_2',
            'BAT_EN_103V_A33_3',
            'BAT_EN_106V_A33_2',
            'BAT_EN_108V_A19_1',
            'BAT_EN_109V_A24_1',
        ],
        METER: [operationSheetNames.BAR_TH_160V_A27_1, operationSheetNames.BAR_TH_160V_A39_2, 'BAT_TH_146V_A31_2'],
        UNIT: [
            'BAR_EN_104V_A14_1',
            'BAR_TH_106V_A23_2',
            'BAR_TH_127V_A36_3',
            'BAR_TH_159V_A26_1_CDP',
            'BAR_TH_159V_A26_1',
            'BAR_TH_159V_A41_2_CDP',
            'BAR_TH_159V_A41_2',
            'BAR_TH_104V_A23_2_CDP',
            'BAR_TH_104V_A23_2',
            'BAR_TH_104V_A41_3_CDP',
            'BAR_TH_104V_A41_3',
            operationSheetNames.BAR_TH_101,
            operationSheetNames.BAR_TH_148,
            operationSheetNames.BAR_TH_129V_A27_3,
            operationSheetNames.BAR_TH_112V_A35_2,
            operationSheetNames.BAR_TH_112V_A35_2_CDP,
        ],
    };
    const matchingUnits = [];
    Object.keys(operationSheetUnits).forEach((unit) => {
        if (operationSheetUnits[unit].includes(operationSheetName)) {
            matchingUnits.push(unit);
        }
    });

    return matchingUnits.join('/');
}

function getSelectTechnicianPlaceholder(operation) {
    const { technicianType, technicianData } = operation.technician;
    if (technicianType === quotationTechnicianTypes.ORGANIZATION) return 'Technicien "Mon Organisation"';

    return technicianData ? `Technicien "${technicianData.name}"` : '';
}

function getSelectTechnicianOptions(subContractors, quotation = {}) {
    if (!subContractors || isNullishOrEmpty(quotation?.operations)) return [];

    const options = [{ label: 'Technicien "Mon Organisation"', value: { technicianType: quotationTechnicianTypes.ORGANIZATION, technicianId: quotation.idOrganization } }];
    if (!isBarTh129VA273IncludedInOperations(quotation.operations)) {
        subContractors.forEach((subContractor) => {
            options.push({ label: `Technicien "${subContractor.name}"`, value: { technicianType: quotationTechnicianTypes.SUB_CONTRACTOR, technicianId: subContractor._id } });
        });
    }

    return options;
}

function isBarTh129VA273IncludedInOperations(operations = []) {
    return operations.find(({ operationSheetName }) => operationSheetName === operationSheetNames.BAR_TH_129V_A27_3);
}

function isOperationUnitValueDifferentOfMainProductQuantity(operationSheetName) {
    return [
        operationSheetNames.BAR_TH_104,
        operationSheetNames.BAR_TH_104_CDP,
        operationSheetNames.BAR_TH_159,
        operationSheetNames.BAR_TH_159_CDP,
        operationSheetNames.BAR_TH_106,
        operationSheetNames.BAR_TH_127,
        operationSheetNames.BAR_TH_129V_A27_3,
    ].includes(operationSheetName);
}

function isOperationTechnicianSubContractor(technician) {
    return technician.technicianType === quotationTechnicianTypes.SUB_CONTRACTOR;
}

function isOperationTechnicianRGECertificateValid(technician) {
    return technician.technicianData.rgeCertificates.length > 0;
}

const operationsThatNeedHeatingTypes = [
    operationSheetNames.BAR_EN_102V_A14_1,
    operationSheetNames.BAR_EN_102V_A39_2,
    operationSheetNames.BAR_EN_104,
    operationSheetNames.BAT_EN_101,
    operationSheetNames.BAT_EN_102,
    operationSheetNames.BAT_EN_103,
];

function isAnOperationThatNeedsHeatingTypes(operation) {
    return operationsThatNeedHeatingTypes.includes(operation.operationSheetName);
}

function getRandomInstallationFinishesDefaultValue() {
    const defaultValues = Object.values(operationInstallationFinishesTranslations).filter((value) => value !== operationInstallationFinishesTranslations.AUTRE);

    return getRandomArrayValue(defaultValues);
}

function isOperationUnitValueEqualToMainProductQuantity({ operationSheetName }) {
    return operationSheetName === operationSheetNames.BAT_EN_109;
}

function findOperationByOperationSheetName(operations, operationSheetName) {
    return operations.find((operation) => operation.operationSheetName === operationSheetName);
}

function isCDPOperation(operationSheetName) {
    return operationSheetName.includes('_CDP');
}

function getValueLabel(productDetailToSpecifyValue) {
    const possibleValues = {
        true: 'Oui',
        false: 'Non',
    };

    return possibleValues[productDetailToSpecifyValue];
}

export default {
    formatOperationFieldLabel,
    hasOperationChanged,
    getOperationSheetNameLabel,
    getOperationSheetsOptions,
    getOperationSelectPlaceholder,
    getHeatingTypesOptions,
    getRadioGroupYesOrNoOptions,
    doesOperationMatchAddressBuildingSector,
    getOperationSheetUnitLabel,
    getSelectTechnicianPlaceholder,
    getSelectTechnicianOptions,
    isOperationUnitValueDifferentOfMainProductQuantity,
    isOperationTechnicianSubContractor,
    isOperationTechnicianRGECertificateValid,
    isAnOperationThatNeedsHeatingTypes,
    getRandomInstallationFinishesDefaultValue,
    getEquipementInstalleOptions,
    isOperationUnitValueEqualToMainProductQuantity,
    findOperationByOperationSheetName,
    isCDPOperation,
    getValueLabel,
};
